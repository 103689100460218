
import { defineComponent } from 'vue';
import VarmaIcon from '../../common/icons/VarmaIcon.vue';
import VarmaHeaderItem from './VarmaHeaderItem.vue';
import VarmaHeaderSearch from './VarmaHeaderSearch.vue';
import { disableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';

export default defineComponent({
    components: {
        VarmaIcon,
        VarmaHeaderItem,
        VarmaHeaderSearch,
    },
    props: {
        loginUrl: { type: String, default: null },
        logoHref: { type: String, default: null },
        logoSrc: { type: String, default: null },
        searchUrl: { type: String, default: null },
    },
    data() {
        return {
            initialized: false,
            languageOpen: false,
            languageItems: [],
            searchOpen: false,
            menuOpen: false,
            lastScroll: 0,
            itemSticky: true,
            itemTop: 0,
            itemHeight: 0,
            tabbedBack: false
        } as {
            initialized: boolean;
            languageOpen: boolean;
            languageItems: any[];
            searchOpen: boolean;
            menuOpen: boolean;
            lastScroll: number;
            itemSticky: boolean;
            itemTop: number;
            itemHeight: number;
            tabbedBack: boolean;
        };
    },
    computed: {
        allLanguagesLabel(): string {	
            if (!window.VarmaI18n) {	
                return '';	
            }	

            return Object.keys(window.VarmaI18n).map(lang => {	
                return (window.VarmaI18n as any)[lang]['/common/language'];	
            }).join(', ');	
        },
        lastElement(): HTMLElement {
            const links = (this.$refs.itemContainer as HTMLElement).querySelectorAll('.top-level.item');

            return (links[links.length -1].firstChild as HTMLElement);
        }
    },
    created() {
        document.addEventListener('click', this.handleLanguageMenuClose);
    },
    mounted() {
        this.$watch('menuOpen', (open: boolean) => {
            this.languageOpen = false;

            if (open && window.innerWidth < 680) { //change breakpoint if varma style breakpoint changes
                this.$nextTick(() => disableBodyScroll(this.$refs.itemContainer as HTMLElement));
            } else {
                clearAllBodyScrollLocks();
            }         
        });

        this.$watch('searchOpen', (open: boolean) => {
            if (open) {
                this.handleSearchOpen();
            }
        });

        document.addEventListener('scroll', this.updateItemContainer);
        document.addEventListener('keyup', this.handleKeyUp);
        document.addEventListener('keydown', this.markTabDirection);
        document.addEventListener('focusin', this.handleFocusin);

        setTimeout(() => {
            this.initialized = true;
        }, 1000);
    },
    methods: {
        updateItemContainer(): void {
            const scroll = window.scrollY;
            const scrollingDown = scroll > this.lastScroll;

            const mainHeight = (this.$refs.mainContainer as HTMLElement).clientHeight;
            const itemHeight = (this.$refs.itemContainer as HTMLElement).clientHeight;

            this.itemHeight = itemHeight;

            if (scrollingDown) {
                if (this.itemSticky) {
                    // Item container being sticky means we just started going down.
                    // Detach item container and position based on last scroll position.
                    // Ensure item container doesn't end up higher than main container bottom.
                    this.itemTop = Math.max(this.lastScroll + mainHeight, mainHeight);
                    this.itemSticky = false;
                }
            } else if (!this.itemSticky) {
                if (this.itemTop < scroll + mainHeight - itemHeight) {
                    // Scrolling up, but item container is too far above to be visible at all.
                    // Move it just above currently visible area based on last scroll position.
                    // Ensure item container doesn't end up higher than main container bottom.
                    this.itemTop = Math.max(this.lastScroll + mainHeight - itemHeight, mainHeight);
                }

                if (this.itemTop >= scroll + mainHeight) {
                    // Scrolled above item container, attach it.
                    this.itemSticky = true;
                }
            }

            this.lastScroll = scroll;
        },
        handleSearchOpen(): void {
            this.$nextTick(() => {
                const mainHeight = (this.$refs.mainContainer as HTMLElement).clientHeight;
                const itemTop = (this.$refs.itemContainer as HTMLElement).offsetTop;

                // Ensure the search input is visible.
                window.scrollTo({
                    top: itemTop - mainHeight
                });

                // Focus handling needs extra care to not break it on iOS.
                (this.$refs.itemSearch as any).focus();
            });
        },
        handleLanguageMenuClose(): void {
            if (this.languageOpen) {
                this.languageOpen = false;
            }
        },
        handleKeyUp(ev: KeyboardEvent): void {
            if (ev.code === 'Escape') {
                this.menuOpen = false;
            }
        },
        markTabDirection(ev: KeyboardEvent): void {
            if (ev.code === 'Tab') {
                this.tabbedBack = ev.shiftKey;
            }
        },
        handleFocusin(ev: FocusEvent): void {
            this.handleMenuFocus(ev);
            this.handleLanguageFocus(ev);
        },
        handleMenuFocus(ev: FocusEvent): void {
            if (this.menuOpen) {
                let element = ev.target as Node;

                while (element !== null) {
                    if (element == this.$refs.mainContainer ||
                        element == this.$refs.itemContainer) {
                        return;
                    }
                    element = element.parentElement as Node;
                }

                if (this.tabbedBack && (!ev.relatedTarget || (ev.relatedTarget as HTMLElement).id === (this.$refs.logo as HTMLElement).id)) {
                    this.lastElement.focus();
                } else {
                    (this.$refs.logo as HTMLElement).focus();
                }
            }
        },
        handleLanguageFocus(ev: FocusEvent): void {
            if (this.languageOpen) {
                let element = ev.target as Node;

                while (element !== null) {
                    if (element == this.$refs.languageMenu) {
                        return;
                    }
                    element = element.parentElement as Node;
                }
                
                this.languageOpen = false;
            }
        },
        addLanguageMenuItem(item: any): void {
            this.languageItems.push(item);
        },
        languageKeydown(ev: KeyboardEvent): void {
            if (!this.languageOpen) {
                return;
            }

            let index = this.languageItems.findIndex(i => (i as any).focused);

            if (ev.key == 'Escape') {
                this.languageOpen = false;
                return;
            } else if (ev.key == 'ArrowUp') {
                index -= 1;
                ev.stopPropagation();
                ev.preventDefault();
            } else if (ev.key == 'ArrowDown') {
                index += 1;
                ev.stopPropagation();
                ev.preventDefault();
            } else {
                return;
            }

            if (index < 0) {
                index = this.languageItems.length - 1;
            } else if (index >= this.languageItems.length) {
                index = 0;
            }

            (this.languageItems[index] as any).focus();
        }
    }
});
