
import { defineComponent } from 'vue';
import VarmaIcon from '../../common/icons/VarmaIcon.vue';

export default defineComponent({
    components: {
        VarmaIcon
    },
    props: {
        href: { type: String, default: null },
        text: { type: String, default: null },
        langCode: { type: String, default: null},
        active: Boolean
    },
    data() {
        return {
            focused: false
        };
    },
    mounted() {
        ((this.$parent as any).$parent as any).addLanguageMenuItem(this);
    },
    methods: {
        focus(): void {
            (this.$refs.element as HTMLElement).focus();
        },
        focusin(): void {
            this.focused = true;
        },
        blur(): void {
            this.focused = false;
        }
    }
});
