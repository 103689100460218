
import { defineComponent } from 'vue';
import VarmaIcon from '../../common/icons/VarmaIcon.vue';
import * as GA from '../../../utils/GA';

export default defineComponent({
    components: {
        VarmaIcon
    },
    props: {
        modelValue: Boolean,
        hasPopup: Boolean,
        href: { type: String, default: null },
        icon: { type: String, default: null },
        iconActive: { type: String, default: null },
        text: { type: String, default: null },
        textActive: { type: String, default: null },
        gaEventObj: { type: Object, default: null },
        screenreaderText: { type: String, default: null}
    },
    emits: ['update:modelValue'],
    data() {
        return {
            hover: false
        };
    },
    methods: {        
        toggle(): void {
            if(this.gaEventObj != null) {
                GA.pushDataLayer(this.gaEventObj);
            }
            this.$emit('update:modelValue', !this.modelValue);
        }
    }
});
