
import { defineComponent } from 'vue';

export default defineComponent({
    methods: {
        urlHashContainsAccordionAnchor(anchorId: string): boolean {
            const hash: string = window.location.hash;
            if (hash) {
                const parsedhash = hash.replace('#','');
                if (anchorId === parsedhash) {
                    return true;
                }
            }
            return false;
        }
    }
});

