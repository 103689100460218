

import { defineComponent } from 'vue';
import VarmaIcon from '../../icons/VarmaIcon.vue';

export default defineComponent({
    components: {
        VarmaIcon
    },
    props: {
        boxed: Boolean,
        noBackground: Boolean,
        isCollapsed: { type: Boolean, required: true },
        anchorId: { type: String, required: false, default: null },
        accordionId: { type: String, required: false, default: null },
        heading: { type: String, required: false, default: '' },
        isSingle: { type: Boolean, required: false },
        clearFocus: { type: Boolean, required: false },
    },
    data() {
        return { 
            isExpanded: !this.isCollapsed
        } as { 
            isExpanded: boolean;
        };
    },
    watch: {
        isExpanded(): void {
            if (this.clearFocus) {
                (document.activeElement! as any).blur();
            }
        }
    },
    methods: {
        enter(element: HTMLElement): void {
            element.style.height = 'auto';
            const height: string | null = getComputedStyle(element).height;
            element.style.height = '0';

            requestAnimationFrame(() => {
                element.style.height = height;
            });
        },
        afterEnter(element: HTMLElement): void {
            element.style.height = 'auto';
        },
        leave(element: HTMLElement): void {
            element.style.height = getComputedStyle(element).height;

            //timeout needed for height setting to take effect
            setTimeout(() => requestAnimationFrame(() => element.style.height = '0'), 10);
        }
    }
});

