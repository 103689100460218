import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveDynamicComponent as _resolveDynamicComponent, withModifiers as _withModifiers, normalizeClass as _normalizeClass, withCtx as _withCtx, renderSlot as _renderSlot, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-a0a7146a"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "item-root" }
const _hoisted_2 = { class: "text" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_varma_icon = _resolveComponent("varma-icon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.href ? 'a' : 'button'), {
      href: _ctx.href,
      class: _normalizeClass([{ active: _ctx.modelValue }, "item"]),
      "aria-haspopup": _ctx.hasPopup ? true : null,
      "aria-expanded": !_ctx.href ? _ctx.modelValue : null,
      "aria-label": _ctx.screenreaderText,
      onClick: _withModifiers(_ctx.toggle, ["stop"]),
      onMouseover: _cache[0] || (_cache[0] = ($event: any) => (_ctx.hover = true)),
      onMouseout: _cache[1] || (_cache[1] = ($event: any) => (_ctx.hover = false))
    }, {
      default: _withCtx(() => [
        (!_ctx.modelValue && !_ctx.hover)
          ? (_openBlock(), _createBlock(_component_varma_icon, {
              key: 0,
              name: _ctx.icon,
              weight: "regular",
              color: "black",
              "vertical-align": "middle"
            }, null, 8, ["name"]))
          : (_ctx.modelValue)
            ? (_openBlock(), _createBlock(_component_varma_icon, {
                key: 1,
                name: _ctx.iconActive || _ctx.icon,
                weight: "regular",
                "vertical-align": "middle"
              }, null, 8, ["name"]))
            : (_openBlock(), _createBlock(_component_varma_icon, {
                key: 2,
                name: _ctx.icon,
                weight: "regular",
                "vertical-align": "middle"
              }, null, 8, ["name"])),
        _createElementVNode("span", _hoisted_2, _toDisplayString((_ctx.modelValue && _ctx.textActive) ? _ctx.textActive : _ctx.text), 1)
      ]),
      _: 1
    }, 8, ["href", "class", "aria-haspopup", "aria-expanded", "aria-label", "onClick"])),
    _renderSlot(_ctx.$slots, "default", {}, undefined, true)
  ]))
}