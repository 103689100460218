import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, renderSlot as _renderSlot, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-945637ae"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["href", "target", "aria-current"]
const _hoisted_2 = { class: "text" }
const _hoisted_3 = ["aria-label", "aria-expanded"]
const _hoisted_4 = {
  key: 0,
  class: "children"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_varma_icon = _resolveComponent("varma-icon")!

  return (_openBlock(), _createElementBlock("div", {
    ref: "element",
    class: _normalizeClass([{ open: _ctx.isOpen, 'top-level': _ctx.topLevel }, "menu-item"]),
    onMouseover: _cache[4] || (_cache[4] = ($event: any) => (_ctx.hovered = true)),
    onMouseleave: _cache[5] || (_cache[5] = ($event: any) => (_ctx.hovered = false))
  }, [
    _createElementVNode("div", {
      class: _normalizeClass([{ active: _ctx.active, 'init-open': _ctx.initOpen, open: _ctx.isOpen, 'top-level': _ctx.topLevel }, "item"])
    }, [
      _createElementVNode("a", {
        ref: "link",
        href: _ctx.href,
        target: _ctx.target,
        "aria-current": _ctx.active ? 'page' : '',
        class: "link",
        onFocusin: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.focusin && _ctx.focusin(...args))),
        onBlur: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.blur && _ctx.blur(...args)))
      }, [
        _createElementVNode("span", _hoisted_2, _toDisplayString(_ctx.text), 1),
        (_ctx.external)
          ? (_openBlock(), _createBlock(_component_varma_icon, {
              key: 0,
              name: "expand-6",
              weight: "regular",
              size: "S",
              color: "black",
              title: _ctx.$t('/common/textlink/external')
            }, null, 8, ["title"]))
          : _createCommentVNode("", true)
      ], 40, _hoisted_1),
      (_ctx.expandable)
        ? (_openBlock(), _createElementBlock("button", {
            key: 0,
            class: "toggle",
            "aria-haspopup": "true",
            "aria-label": _ctx.buttonAriaLabel,
            "aria-expanded": _ctx.isOpen,
            onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.toggle && _ctx.toggle(...args))),
            onTouchstart: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.touchstart && _ctx.touchstart(...args)))
          }, [
            _createVNode(_component_varma_icon, {
              name: "expand",
              weight: "bold",
              color: "black",
              size: "XS",
              "vertical-align": "middle",
              class: "expand-icon"
            }),
            _createVNode(_component_varma_icon, {
              name: "shrink",
              weight: "bold",
              color: "black",
              size: "XS",
              "vertical-align": "middle",
              class: "shrink-icon"
            })
          ], 40, _hoisted_3))
        : _createCommentVNode("", true)
    ], 2),
    (_ctx.expandable)
      ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
          _renderSlot(_ctx.$slots, "default", {}, undefined, true)
        ]))
      : _createCommentVNode("", true)
  ], 34))
}