import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, renderSlot as _renderSlot, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-06bf50d2"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "varma-tab-group" }
const _hoisted_2 = { class: "varma-tab-header" }
const _hoisted_3 = { class: "varma-tab-label-container" }
const _hoisted_4 = {
  role: "tablist",
  class: "varma-tab-list"
}
const _hoisted_5 = { class: "varma-tab-labels" }
const _hoisted_6 = ["aria-selected", "onClick"]
const _hoisted_7 = { class: "varma-tab-label-content" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("div", _hoisted_5, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.tabs, (tab, index) => {
              return (_openBlock(), _createElementBlock("div", {
                key: index,
                class: _normalizeClass(["varma-tab-label varma-focus-indicator", { 'varma-tab-label-active': tab.active }]),
                "aria-selected": tab.active,
                role: "tab",
                tabindex: "0",
                onClick: ($event: any) => (_ctx.selectTab(tab))
              }, [
                _createElementVNode("div", _hoisted_7, _toDisplayString(tab.label), 1)
              ], 10, _hoisted_6))
            }), 128))
          ])
        ])
      ])
    ]),
    _renderSlot(_ctx.$slots, "default", {}, undefined, true)
  ]))
}