import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-1f3b24c0"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "grid-contain" }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = { class: "report__key-figures report--color-primary" }
const _hoisted_4 = { class: "report__key-figures__date" }
const _hoisted_5 = { class: "report__key-figures__items" }
const _hoisted_6 = { class: "report__key-figures__item" }
const _hoisted_7 = { class: "report__key-figures__item-title-inline" }
const _hoisted_8 = { class: "report__key-figures__item" }
const _hoisted_9 = { class: "report__key-figures__item-title" }
const _hoisted_10 = { class: "report__key-figures__item-value" }
const _hoisted_11 = { class: "report__key-figures__item-value-suffix" }
const _hoisted_12 = {
  key: 0,
  class: "report__key-figures__item"
}
const _hoisted_13 = { class: "report__key-figures__item-title" }
const _hoisted_14 = { class: "report__key-figures__item-value" }
const _hoisted_15 = { class: "grid-base report__charts" }
const _hoisted_16 = { class: "report__chart" }
const _hoisted_17 = { class: "report__chart-title" }
const _hoisted_18 = { class: "report__chart-chart" }
const _hoisted_19 = { class: "report__chart" }
const _hoisted_20 = { class: "report__chart-title" }
const _hoisted_21 = { class: "report__chart-chart" }
const _hoisted_22 = { class: "report__key-figures report--color-primary" }
const _hoisted_23 = { class: "report__key-figures__date" }
const _hoisted_24 = { class: "report__key-figures__items" }
const _hoisted_25 = { class: "report__key-figures__item" }
const _hoisted_26 = { class: "report__key-figures__item-title-inline" }
const _hoisted_27 = { class: "report__key-figures__item" }
const _hoisted_28 = { class: "report__key-figures__item-title" }
const _hoisted_29 = { class: "report__key-figures__item-value" }
const _hoisted_30 = { class: "report__key-figures__item-value-suffix" }
const _hoisted_31 = { class: "report__key-figures__item" }
const _hoisted_32 = { class: "report__key-figures__item-title" }
const _hoisted_33 = { class: "report__key-figures__item-value" }
const _hoisted_34 = { class: "report__key-figures__item-value-suffix" }
const _hoisted_35 = { class: "grid-base report__charts" }
const _hoisted_36 = { class: "report__chart" }
const _hoisted_37 = { class: "report__chart-title" }
const _hoisted_38 = { class: "report__chart-chart" }
const _hoisted_39 = { class: "report__chart" }
const _hoisted_40 = { class: "report__chart-title" }
const _hoisted_41 = { class: "report__chart-chart" }
const _hoisted_42 = { class: "report__key-figures report--color-primary" }
const _hoisted_43 = { class: "report__key-figures__date" }
const _hoisted_44 = { class: "report__key-figures__items" }
const _hoisted_45 = { class: "report__key-figures__item" }
const _hoisted_46 = { class: "report__key-figures__item-title-inline" }
const _hoisted_47 = { class: "report__key-figures__item" }
const _hoisted_48 = { class: "report__key-figures__item-title" }
const _hoisted_49 = { class: "grid-base report__charts report__charts--half" }
const _hoisted_50 = { class: "report__text-half" }
const _hoisted_51 = { class: "content-text" }
const _hoisted_52 = { class: "report__chart" }
const _hoisted_53 = { class: "report__chart-title" }
const _hoisted_54 = { class: "report__chart-chart" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_varma_icon = _resolveComponent("varma-icon")!
  const _component_varma_heading = _resolveComponent("varma-heading")!
  const _component_highcharts = _resolveComponent("highcharts")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.source)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.sourceDateText), 1),
            _createElementVNode("div", _hoisted_5, [
              _createElementVNode("div", _hoisted_6, [
                _createVNode(_component_varma_icon, {
                  name: "team-meeting",
                  weight: "light",
                  size: "XL",
                  color: "primary",
                  class: "report__key-figures__item-icon-xl"
                }),
                _createVNode(_component_varma_icon, {
                  name: "team-meeting",
                  weight: "light",
                  size: "L",
                  color: "primary",
                  class: "report__key-figures__item-icon-l"
                }),
                _createElementVNode("span", _hoisted_7, _toDisplayString(_ctx.source.specialistServices.operatingYear), 1)
              ]),
              _createElementVNode("div", _hoisted_8, [
                _createVNode(_component_varma_heading, null, {
                  default: _withCtx(() => [
                    _createElementVNode("h2", _hoisted_9, _toDisplayString(_ctx.$t('/blocks/workabilityreport/specialistservicesinitiated')), 1)
                  ]),
                  _: 1
                }),
                _createElementVNode("div", null, [
                  _createElementVNode("span", _hoisted_10, _toDisplayString(_ctx.source.specialistServices.servicesInitiated.toLocaleString(_ctx.language)), 1),
                  _createElementVNode("span", _hoisted_11, _toDisplayString(_ctx.$t('/blocks/workabilityreport/piecessuffix')), 1)
                ])
              ]),
              (_ctx.feedbackScore)
                ? (_openBlock(), _createElementBlock("div", _hoisted_12, [
                    _createVNode(_component_varma_heading, null, {
                      default: _withCtx(() => [
                        _createElementVNode("h2", _hoisted_13, _toDisplayString(_ctx.$t('/blocks/workabilityreport/feedbackscore')), 1)
                      ]),
                      _: 1
                    }),
                    _createElementVNode("div", _hoisted_14, _toDisplayString(_ctx.feedbackScoreText), 1)
                  ]))
                : _createCommentVNode("", true)
            ])
          ]),
          _createElementVNode("div", _hoisted_15, [
            _createElementVNode("div", _hoisted_16, [
              _createVNode(_component_varma_heading, null, {
                default: _withCtx(() => [
                  _createElementVNode("h2", _hoisted_17, _toDisplayString(_ctx.$t('/blocks/workabilityreport/specialistservicesbreakdown')), 1)
                ]),
                _: 1
              }),
              _createElementVNode("div", _hoisted_18, [
                _createVNode(_component_highcharts, { options: _ctx.servicesBreakdownChart }, null, 8, ["options"])
              ])
            ]),
            _createElementVNode("div", _hoisted_19, [
              _createVNode(_component_varma_heading, null, {
                default: _withCtx(() => [
                  _createElementVNode("h2", _hoisted_20, _toDisplayString(_ctx.$t('/blocks/workabilityreport/actiontargetgroups')), 1)
                ]),
                _: 1
              }),
              _createElementVNode("div", _hoisted_21, [
                _createVNode(_component_highcharts, { options: _ctx.actionTargetGroupsChart }, null, 8, ["options"])
              ])
            ])
          ]),
          _createElementVNode("div", _hoisted_22, [
            _createElementVNode("div", _hoisted_23, _toDisplayString(_ctx.sourceDateText), 1),
            _createElementVNode("div", _hoisted_24, [
              _createElementVNode("div", _hoisted_25, [
                _createVNode(_component_varma_icon, {
                  name: "business-contract-approve",
                  weight: "light",
                  size: "XL",
                  color: "primary",
                  class: "report__key-figures__item-icon-xl"
                }),
                _createVNode(_component_varma_icon, {
                  name: "business-contract-approve",
                  weight: "light",
                  size: "L",
                  color: "primary",
                  class: "report__key-figures__item-icon-l"
                }),
                _createElementVNode("span", _hoisted_26, _toDisplayString(_ctx.source.actions.operatingYear), 1)
              ]),
              _createElementVNode("div", _hoisted_27, [
                _createVNode(_component_varma_heading, null, {
                  default: _withCtx(() => [
                    _createElementVNode("h2", _hoisted_28, _toDisplayString(_ctx.$t('/blocks/workabilityreport/numberofactions')), 1)
                  ]),
                  _: 1
                }),
                _createElementVNode("div", null, [
                  _createElementVNode("span", _hoisted_29, _toDisplayString(_ctx.source.actions.numberOfActions.toLocaleString(_ctx.language)), 1),
                  _createElementVNode("span", _hoisted_30, _toDisplayString(_ctx.$t('/blocks/workabilityreport/piecessuffix')), 1)
                ])
              ]),
              _createElementVNode("div", _hoisted_31, [
                _createVNode(_component_varma_heading, null, {
                  default: _withCtx(() => [
                    _createElementVNode("h2", _hoisted_32, _toDisplayString(_ctx.$t('/blocks/workabilityreport/actionstotalsum')), 1)
                  ]),
                  _: 1
                }),
                _createElementVNode("div", null, [
                  _createElementVNode("span", _hoisted_33, _toDisplayString(_ctx.actionsTotalSumEurosText[0]), 1),
                  _createElementVNode("span", _hoisted_34, _toDisplayString(_ctx.actionsTotalSumEurosText[1]), 1)
                ])
              ])
            ])
          ]),
          _createElementVNode("div", _hoisted_35, [
            _createElementVNode("div", _hoisted_36, [
              _createVNode(_component_varma_heading, null, {
                default: _withCtx(() => [
                  _createElementVNode("h2", _hoisted_37, _toDisplayString(_ctx.$t('/blocks/workabilityreport/actionthemes')), 1)
                ]),
                _: 1
              }),
              _createElementVNode("div", _hoisted_38, [
                _createVNode(_component_highcharts, { options: _ctx.actionThemesChart }, null, 8, ["options"])
              ])
            ]),
            _createElementVNode("div", _hoisted_39, [
              _createVNode(_component_varma_heading, null, {
                default: _withCtx(() => [
                  _createElementVNode("h2", _hoisted_40, _toDisplayString(_ctx.$t('/blocks/workabilityreport/actiontargetedrisks')), 1)
                ]),
                _: 1
              }),
              _createElementVNode("div", _hoisted_41, [
                _createVNode(_component_highcharts, { options: _ctx.targetedRisksChart }, null, 8, ["options"])
              ])
            ])
          ]),
          _createElementVNode("div", _hoisted_42, [
            _createElementVNode("div", _hoisted_43, _toDisplayString(_ctx.sourceDateText), 1),
            _createElementVNode("div", _hoisted_44, [
              _createElementVNode("div", _hoisted_45, [
                _createVNode(_component_varma_icon, {
                  name: "performance-increase-1",
                  weight: "light",
                  size: "XL",
                  color: "primary",
                  class: "report__key-figures__item-icon-xl"
                }),
                _createVNode(_component_varma_icon, {
                  name: "performance-increase-1",
                  weight: "light",
                  size: "L",
                  color: "primary",
                  class: "report__key-figures__item-icon-l"
                }),
                _createElementVNode("span", _hoisted_46, _toDisplayString(_ctx.source.actions.operatingYear), 1)
              ]),
              _createElementVNode("div", _hoisted_47, [
                _createVNode(_component_varma_heading, null, {
                  default: _withCtx(() => [
                    _createElementVNode("h2", _hoisted_48, _toDisplayString(_ctx.$t('/blocks/workabilityreport/customeractionindicators')), 1)
                  ]),
                  _: 1
                })
              ])
            ])
          ]),
          _createElementVNode("div", _hoisted_49, [
            _createElementVNode("div", _hoisted_50, [
              _createElementVNode("div", _hoisted_51, [
                _renderSlot(_ctx.$slots, "indicators-body", {}, undefined, true)
              ])
            ]),
            _createElementVNode("div", _hoisted_52, [
              _createVNode(_component_varma_heading, null, {
                default: _withCtx(() => [
                  _createElementVNode("h2", _hoisted_53, _toDisplayString(_ctx.$t('/blocks/workabilityreport/actionindicators')), 1)
                ]),
                _: 1
              }),
              _createElementVNode("div", _hoisted_54, [
                _createVNode(_component_highcharts, { options: _ctx.indicatorsChart }, null, 8, ["options"])
              ])
            ])
          ])
        ]))
      : _createCommentVNode("", true)
  ]))
}