
import { defineComponent } from 'vue';
import VarmaIcon from '../../common/icons/VarmaIcon.vue';

export default defineComponent({
    components: {
        VarmaIcon
    },
    props: {
        active: Boolean,
        initOpen: Boolean,
        href: { type: String, default: null },
        target: { type: String, default: null },
        text: { type: String, default: null },
        external: Boolean,
        topLevel: Boolean,
        expandable: Boolean,
    },
    data(){
        return {
            hovered: false,
            toggled: false,
            focused: false,
            hastouch: false,
            menuItems: []
        } as {
            hovered: boolean;
            toggled: boolean;
            focused: boolean;
            hastouch: boolean;
            menuItems: any[];
        };
    },
    computed: {
        buttonAriaLabel(): string {
            let text = this.$t('/navigation/menugrouparia');
            if (text.includes('{text}')) { 
                text = text.replace('{text}', this.text || ''); 
            }

            return text;
        },
        isOpen(): boolean {
            return this.toggled || (!this.hastouch && this.hovered);
        }
    },
    mounted() {
        if ((this.$parent as any).addMenuItem) {
            (this.$parent as any).addMenuItem(this);
        }

        if (this.topLevel) {
            document.addEventListener('focusin', this.handleDocumentFocusin);
            document.addEventListener('click', this.handleDocumentClick);
            document.addEventListener('keydown', this.handleDocumentKeydown);
        }

        // Menu item initializes expanded if it was open (i.e. current page or ancestor)
        // and window width is below the always visible hoverable main menu threshold
        if (this.initOpen && !this.usingExtendedMenu()) {
            this.$nextTick(() => this.toggle());
        }
    },
    methods: {
        usingExtendedMenu(): boolean {
            return window.innerWidth >= 1200;
        },
        addMenuItem(item: any): void {
            this.menuItems.push(item);
        },
        toggle(): void {
            this.toggled = !this.toggled;
        },
        touchstart(): void {
            this.hastouch = true;
        },
        focus(): void {
            (this.$refs.link as HTMLElement).focus();
        },
        focusin(): void {
            this.focused = true;
        },
        blur(): void {
            this.focused = false;
        },
        isElementOutside(element: Node): boolean {
            while (element !== null) {
                if (element == this.$refs.element) {
                    return false;
                }
                element = element.parentElement as Node;
            }

            return true;
        },
        handleDocumentFocusin(ev: FocusEvent): void {
            if (this.isOpen && this.usingExtendedMenu()) {
                if (this.isElementOutside(ev.target as Node)) {
                    this.isOpen = false;
                }
            }
        },
        handleDocumentClick(ev: MouseEvent): void {
            if (this.isOpen && this.usingExtendedMenu()) {
                if (this.isElementOutside(ev.target as Node)) {
                    this.isOpen = false;
                }
            }
        },
        handleDocumentKeydown(ev: KeyboardEvent): void {
            if (this.isOpen && this.usingExtendedMenu()) {
                if (ev.key == 'Escape') {
                    this.toggled = false;
                    this.hovered = false;
                }
            }
        },
        keyup(ev: KeyboardEvent): void {
            if (!this.isOpen || !this.usingExtendedMenu()) {
                return;
            }

            let index = this.menuItems.findIndex(i => (i as any).focused);

            if (ev.key == 'ArrowUp') {
                index -= 1;
                ev.stopPropagation();
                ev.preventDefault();
            } else if (ev.key == 'ArrowDown') {
                index += 1;
                ev.stopPropagation();
                ev.preventDefault();
            } else {
                return;
            }

            if (index < 0) {
                index = this.menuItems.length - 1;
            } else if (index >= this.menuItems.length) {
                index = 0;
            }

            (this.menuItems[index] as any).focus();
        }
    },
});
