import { normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, resolveComponent as _resolveComponent, createVNode as _createVNode } from "vue"

const _hoisted_1 = ["href", "target"]
const _hoisted_2 = { class: "article-card__image-overlay" }
const _hoisted_3 = {
  key: 0,
  class: "article-card__category"
}
const _hoisted_4 = { class: "article-card__content" }
const _hoisted_5 = {
  key: 0,
  class: "article-card__intro"
}
const _hoisted_6 = {
  key: 1,
  class: "article-card__line"
}
const _hoisted_7 = { class: "article-card__line-text" }
const _hoisted_8 = {
  key: 2,
  class: "article-card__line"
}
const _hoisted_9 = { class: "article-card__line-text" }
const _hoisted_10 = {
  key: 3,
  class: "article-card__line"
}
const _hoisted_11 = { style: {"display":"inline-block","width":"0px","height":"0px","overflow":"hidden"} }
const _hoisted_12 = { class: "article-card__line-text" }
const _hoisted_13 = {
  key: 4,
  class: "article-card__details"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_varma_icon = _resolveComponent("varma-icon")!

  return (_openBlock(), _createElementBlock("a", {
    ref: "articleLink",
    href: _ctx.article.url,
    target: _ctx.article.target,
    class: "article-card"
  }, [
    _createElementVNode("div", {
      class: "article-card__image",
      style: _normalizeStyle(_ctx.getImageStyle)
    }, null, 4),
    _createElementVNode("div", _hoisted_2, [
      (_ctx.article.typeText && !_ctx.isEvent)
        ? (_openBlock(), _createElementBlock("div", _hoisted_3, _toDisplayString(_ctx.article.typeText), 1))
        : _createCommentVNode("", true)
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createElementVNode("div", {
        class: _normalizeClass(["article-card__title", { 'article-card__title--bold' : _ctx.article.intro }])
      }, _toDisplayString(_ctx.article.title), 3),
      (_ctx.article.intro)
        ? (_openBlock(), _createElementBlock("div", _hoisted_5, _toDisplayString(_ctx.article.intro), 1))
        : _createCommentVNode("", true),
      (_ctx.isEvent && _ctx.article.dateText)
        ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
            _createVNode(_component_varma_icon, {
              size: "S",
              color: "black",
              name: "calendar",
              weight: "light",
              "vertical-align": "middle",
              "aria-label": _ctx.$t('/pages/event/date')
            }, null, 8, ["aria-label"]),
            _createElementVNode("span", _hoisted_7, _toDisplayString(_ctx.article.dateText), 1)
          ]))
        : _createCommentVNode("", true),
      (_ctx.isEvent && _ctx.article.eventTypeText)
        ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
            _createVNode(_component_varma_icon, {
              size: "S",
              color: "black",
              name: "pin-location-1",
              weight: "light",
              "vertical-align": "middle",
              "aria-label": _ctx.$t('/pages/event/type')
            }, null, 8, ["aria-label"]),
            _createElementVNode("span", _hoisted_9, _toDisplayString(_ctx.article.eventTypeText), 1)
          ]))
        : _createCommentVNode("", true),
      (_ctx.isEvent && _ctx.article.eventCapacityText)
        ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
            _createElementVNode("span", {
              class: _normalizeClass(`article-card__event-capacity article-card__event-capacity--${_ctx.article.eventCapacity}`)
            }, null, 2),
            _createElementVNode("div", _hoisted_11, _toDisplayString(_ctx.$t('/pages/event/status')), 1),
            _createElementVNode("span", _hoisted_12, _toDisplayString(_ctx.article.eventCapacityText), 1)
          ]))
        : _createCommentVNode("", true),
      (!_ctx.isEvent)
        ? (_openBlock(), _createElementBlock("div", _hoisted_13, [
            _createElementVNode("span", null, _toDisplayString(_ctx.article.keywords), 1),
            _createElementVNode("span", null, _toDisplayString(_ctx.article.dateText), 1)
          ]))
        : _createCommentVNode("", true)
    ])
  ], 8, _hoisted_1))
}