import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-04dce2c9"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "item" }
const _hoisted_2 = ["href", "aria-current"]
const _hoisted_3 = { class: "text" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("a", {
      href: _ctx.href,
      class: _normalizeClass([{ active: _ctx.active }, "item-content"]),
      "aria-current": _ctx.active ? 'true': ''
    }, [
      _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.text), 1)
    ], 10, _hoisted_2)
  ]))
}