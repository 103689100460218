import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-574fa950"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "yel-income-calculator" }
const _hoisted_2 = { class: "yel-income-calculator__title" }
const _hoisted_3 = {
  class: "yel-income-calculator__calculator",
  action: ""
}
const _hoisted_4 = {
  key: 0,
  class: "yel-income-calculator__result"
}
const _hoisted_5 = {
  ref: "resultHeading",
  class: "yel-income-calculator__result-title",
  tabindex: "-1"
}
const _hoisted_6 = { class: "yel-income-calculator__result-income" }
const _hoisted_7 = { class: "yel-income-calculator__result-value" }
const _hoisted_8 = { class: "yel-income-calculator__result-unit" }
const _hoisted_9 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_varma_icon = _resolveComponent("varma-icon")!
  const _component_varma_heading = _resolveComponent("varma-heading")!
  const _component_varma_input = _resolveComponent("varma-input")!
  const _component_varma_combo_box = _resolveComponent("varma-combo-box")!
  const _component_varma_button = _resolveComponent("varma-button")!
  const _component_varma_feedback = _resolveComponent("varma-feedback")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_varma_icon, {
        size: "L",
        color: "primary",
        name: "accounting-calculator",
        weight: "light",
        "vertical-align": "middle",
        class: "yel-income-calculator__icon"
      }),
      _createVNode(_component_varma_heading, null, {
        default: _withCtx(() => [
          _createElementVNode("h2", _hoisted_2, _toDisplayString(_ctx.$t('/blocks/yelincomecalculator/title')), 1)
        ]),
        _: 1
      }),
      _createElementVNode("form", _hoisted_3, [
        _createVNode(_component_varma_input, {
          ref: "incomeField",
          modelValue: _ctx.incomeEstimate,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.incomeEstimate) = $event)),
          "label-text": _ctx.$t('/blocks/yelincomecalculator/income'),
          "unit-text": "€",
          "hint-text": _ctx.$t('/blocks/yelincomecalculator/incomeInfo'),
          class: "yel-income-calculator__income-input",
          rules: [_ctx.validateIncome]
        }, null, 8, ["modelValue", "label-text", "hint-text", "rules"]),
        _createVNode(_component_varma_combo_box, {
          ref: "industryField",
          modelValue: _ctx.industry,
          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.industry) = $event)),
          options: _ctx.industryOptions,
          "dropdown-label": _ctx.$t('/blocks/yelincomecalculator/industries'),
          rules: [_ctx.validateIndustry]
        }, {
          label: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.$t('/blocks/yelincomecalculator/industry')), 1)
          ]),
          help: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.$t('/blocks/yelincomecalculator/industryInfo')), 1)
          ]),
          _: 1
        }, 8, ["modelValue", "options", "dropdown-label", "rules"]),
        _createVNode(_component_varma_button, {
          type: "button",
          class: "yel-income-calculator__submit",
          disabled: _ctx.loading,
          onClick: _ctx.submit
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.$t('/blocks/yelincomecalculator/submit')), 1)
          ]),
          _: 1
        }, 8, ["disabled", "onClick"])
      ]),
      (_ctx.result)
        ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
            _createElementVNode("h3", _hoisted_5, [
              _createTextVNode(_toDisplayString(_ctx.$t('/blocks/yelincomecalculator/resultTitle')) + " ", 1),
              _createElementVNode("span", _hoisted_6, [
                _createElementVNode("span", _hoisted_7, _toDisplayString(_ctx.incomeRecommendation), 1),
                _createElementVNode("span", _hoisted_8, _toDisplayString(_ctx.$t('/unit/eurperyear')), 1)
              ])
            ], 512),
            _createElementVNode("p", {
              class: "yel-income-calculator__result-text",
              innerHTML: _ctx.resultInfo
            }, null, 8, _hoisted_9),
            _renderSlot(_ctx.$slots, "resultbuttons", {}, undefined, true)
          ]))
        : _createCommentVNode("", true)
    ]),
    _createVNode(_component_varma_feedback, {
      toast: "",
      timeout: "",
      type: _ctx.feedbackType,
      visible: _ctx.error,
      onHidden: _cache[2] || (_cache[2] = ($event: any) => (_ctx.error = false))
    }, {
      "feedback-content": _withCtx(() => [
        _createTextVNode(_toDisplayString(_ctx.$t('/forms/error/servererror')), 1)
      ]),
      _: 1
    }, 8, ["type", "visible"])
  ], 64))
}