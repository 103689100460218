
import { defineComponent } from 'vue';
import VarmaIcon from '../../common/icons/VarmaIcon.vue';

export default defineComponent({
    components: {
        VarmaIcon,
    },
    props: {
        searchUrl: { type: String, default: null }
    },
    methods: {
        focus(): void {
            (this.$refs['input'] as HTMLInputElement).focus();
        }
    }
});
