import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createVNode as _createVNode, createTextVNode as _createTextVNode, Fragment as _Fragment, createElementBlock as _createElementBlock, withModifiers as _withModifiers, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-0c75159e"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "gdpr-form" }
const _hoisted_2 = { class: "grid-contain" }
const _hoisted_3 = { class: "content col6-m-width" }
const _hoisted_4 = { class: "title heading-l" }
const _hoisted_5 = { class: "form-pair" }
const _hoisted_6 = { style: {"margin-bottom":"8px"} }
const _hoisted_7 = { class: "form-actions" }
const _hoisted_8 = { class: "title heading-l" }
const _hoisted_9 = { class: "confirm-field" }
const _hoisted_10 = { class: "label" }
const _hoisted_11 = { class: "value" }
const _hoisted_12 = { class: "confirm-field" }
const _hoisted_13 = { class: "label" }
const _hoisted_14 = { class: "value" }
const _hoisted_15 = { class: "confirm-field" }
const _hoisted_16 = { class: "label" }
const _hoisted_17 = { class: "value" }
const _hoisted_18 = { class: "form-pair" }
const _hoisted_19 = { class: "confirm-field" }
const _hoisted_20 = { class: "label" }
const _hoisted_21 = { class: "value" }
const _hoisted_22 = { class: "confirm-field" }
const _hoisted_23 = { class: "label" }
const _hoisted_24 = { class: "value" }
const _hoisted_25 = { class: "confirm-field" }
const _hoisted_26 = { class: "label" }
const _hoisted_27 = { class: "value" }
const _hoisted_28 = { class: "form-actions" }
const _hoisted_29 = { class: "success-icon" }
const _hoisted_30 = { class: "title heading-l" }
const _hoisted_31 = { class: "content-text" }
const _hoisted_32 = { class: "return-link" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_varma_form_auth = _resolveComponent("varma-form-auth")!
  const _component_varma_input = _resolveComponent("varma-input")!
  const _component_varma_select = _resolveComponent("varma-select")!
  const _component_varma_button = _resolveComponent("varma-button")!
  const _component_varma_icon = _resolveComponent("varma-icon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        (_ctx.state != 'Sent')
          ? (_openBlock(), _createBlock(_component_varma_form_auth, {
              key: 0,
              identity: _ctx.state != 'Start' ? _ctx.identity : null,
              "is-gdpr-admin": _ctx.isGdprAdmin
            }, {
              title: _withCtx(() => [
                _renderSlot(_ctx.$slots, "title", {}, undefined, true)
              ]),
              body: _withCtx(() => [
                _renderSlot(_ctx.$slots, "body", {}, undefined, true)
              ]),
              _: 3
            }, 8, ["identity", "is-gdpr-admin"]))
          : _createCommentVNode("", true),
        (_ctx.state == 'Form')
          ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
              _createElementVNode("h3", _hoisted_4, _toDisplayString(_ctx.$t('/blocks/gdprform/formtitle')), 1),
              _createVNode(_component_varma_input, {
                ref: "email",
                modelValue: _ctx.form.email,
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.form.email) = $event)),
                type: "email",
                "label-text": _ctx.$t('/blocks/gdprform/email'),
                "hint-text": _ctx.$t('/blocks/gdprform/emailinstructions'),
                rules: [_ctx.validateField, _ctx.validateEmail],
                required: ""
              }, null, 8, ["modelValue", "label-text", "hint-text", "rules"]),
              _createVNode(_component_varma_input, {
                ref: "emailAgain",
                modelValue: _ctx.form.emailAgain,
                "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.form.emailAgain) = $event)),
                type: "email",
                "label-text": _ctx.$t('/blocks/gdprform/emailagain'),
                "hint-text": _ctx.$t('/blocks/gdprform/emailagaininstructions'),
                rules: [_ctx.validateField, _ctx.validateEmailAgain],
                required: ""
              }, null, 8, ["modelValue", "label-text", "hint-text", "rules"]),
              _createVNode(_component_varma_input, {
                ref: "streetAddress",
                modelValue: _ctx.form.streetAddress,
                "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.form.streetAddress) = $event)),
                "label-text": _ctx.$t('/blocks/gdprform/streetaddress'),
                "hint-text": _ctx.$t('/blocks/gdprform/streetaddressinstructions'),
                rules: [_ctx.validateField],
                required: ""
              }, null, 8, ["modelValue", "label-text", "hint-text", "rules"]),
              _createElementVNode("div", _hoisted_5, [
                _createVNode(_component_varma_input, {
                  ref: "postalCode",
                  modelValue: _ctx.form.postalCode,
                  "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.form.postalCode) = $event)),
                  "label-text": _ctx.$t('/blocks/gdprform/postalcode'),
                  rules: [_ctx.validateField, _ctx.validatePostalCode],
                  required: "",
                  style: {"max-width":"134px"}
                }, null, 8, ["modelValue", "label-text", "rules"]),
                _createVNode(_component_varma_input, {
                  ref: "city",
                  modelValue: _ctx.form.city,
                  "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.form.city) = $event)),
                  "label-text": _ctx.$t('/blocks/gdprform/city'),
                  rules: [_ctx.validateField],
                  required: ""
                }, null, 8, ["modelValue", "label-text", "rules"])
              ]),
              _createElementVNode("p", _hoisted_6, _toDisplayString(_ctx.$t('/blocks/gdprform/roleinstructions')), 1),
              _createVNode(_component_varma_select, {
                ref: "role",
                modelValue: _ctx.form.role,
                "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.form.role) = $event)),
                options: _ctx.roles,
                "label-text": _ctx.$t('/blocks/gdprform/role'),
                rules: [_ctx.validateField],
                required: ""
              }, null, 8, ["modelValue", "options", "label-text", "rules"]),
              _createElementVNode("div", _hoisted_7, [
                _createVNode(_component_varma_button, { onClick: _ctx.next }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.$t('/blocks/gdprform/continue')), 1)
                  ]),
                  _: 1
                }, 8, ["onClick"]),
                _createVNode(_component_varma_button, {
                  ghost: "",
                  onClick: _ctx.reset
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.$t('/blocks/gdprform/abort')), 1)
                  ]),
                  _: 1
                }, 8, ["onClick"])
              ])
            ], 64))
          : (_ctx.state == 'Confirm' || _ctx.state == 'Sending')
            ? (_openBlock(), _createElementBlock(_Fragment, { key: 2 }, [
                _createElementVNode("h3", _hoisted_8, _toDisplayString(_ctx.$t('/blocks/gdprform/confirmtitle')), 1),
                _createElementVNode("div", _hoisted_9, [
                  _createElementVNode("span", _hoisted_10, _toDisplayString(_ctx.$t('/blocks/gdprform/email')), 1),
                  _createElementVNode("span", _hoisted_11, _toDisplayString(_ctx.form.email), 1)
                ]),
                _createElementVNode("div", _hoisted_12, [
                  _createElementVNode("span", _hoisted_13, _toDisplayString(_ctx.$t('/blocks/gdprform/emailagain')), 1),
                  _createElementVNode("span", _hoisted_14, _toDisplayString(_ctx.form.emailAgain), 1)
                ]),
                _createElementVNode("div", _hoisted_15, [
                  _createElementVNode("span", _hoisted_16, _toDisplayString(_ctx.$t('/blocks/gdprform/streetaddress')), 1),
                  _createElementVNode("span", _hoisted_17, _toDisplayString(_ctx.form.streetAddress), 1)
                ]),
                _createElementVNode("div", _hoisted_18, [
                  _createElementVNode("div", _hoisted_19, [
                    _createElementVNode("span", _hoisted_20, _toDisplayString(_ctx.$t('/blocks/gdprform/postalcode')), 1),
                    _createElementVNode("span", _hoisted_21, _toDisplayString(_ctx.form.postalCode), 1)
                  ]),
                  _createElementVNode("div", _hoisted_22, [
                    _createElementVNode("span", _hoisted_23, _toDisplayString(_ctx.$t('/blocks/gdprform/city')), 1),
                    _createElementVNode("span", _hoisted_24, _toDisplayString(_ctx.form.city), 1)
                  ])
                ]),
                _createElementVNode("div", _hoisted_25, [
                  _createElementVNode("span", _hoisted_26, _toDisplayString(_ctx.$t('/blocks/gdprform/role')), 1),
                  _createElementVNode("span", _hoisted_27, _toDisplayString(_ctx.form.role), 1)
                ]),
                _createElementVNode("div", _hoisted_28, [
                  _createVNode(_component_varma_button, {
                    disabled: _ctx.sending,
                    onClick: _ctx.submit
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.$t('/blocks/gdprform/submit')), 1)
                    ]),
                    _: 1
                  }, 8, ["disabled", "onClick"]),
                  _createVNode(_component_varma_button, {
                    ghost: "",
                    disabled: _ctx.sending,
                    onClick: _ctx.editForm
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.$t('/blocks/gdprform/editform')), 1)
                    ]),
                    _: 1
                  }, 8, ["disabled", "onClick"]),
                  _createVNode(_component_varma_button, {
                    ghost: "",
                    disabled: _ctx.sending,
                    onClick: _ctx.reset
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.$t('/blocks/gdprform/abort')), 1)
                    ]),
                    _: 1
                  }, 8, ["disabled", "onClick"])
                ])
              ], 64))
            : (_ctx.state == 'Sent')
              ? (_openBlock(), _createElementBlock(_Fragment, { key: 3 }, [
                  _createElementVNode("div", _hoisted_29, [
                    _createVNode(_component_varma_icon, {
                      name: "check-circle",
                      size: "L",
                      color: "status-success",
                      weight: "bold"
                    })
                  ]),
                  _createElementVNode("h2", _hoisted_30, [
                    _renderSlot(_ctx.$slots, "sent-title", {}, undefined, true)
                  ]),
                  _createElementVNode("div", _hoisted_31, [
                    _renderSlot(_ctx.$slots, "sent-body", {}, undefined, true),
                    _createElementVNode("div", _hoisted_32, [
                      _createElementVNode("a", {
                        href: "#",
                        onClick: _cache[6] || (_cache[6] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.reset && _ctx.reset(...args)), ["prevent"]))
                      }, _toDisplayString(_ctx.$t('/blocks/gdprform/return')), 1)
                    ])
                  ])
                ], 64))
              : _createCommentVNode("", true)
      ])
    ])
  ]))
}