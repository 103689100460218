import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, renderSlot as _renderSlot, createElementVNode as _createElementVNode, vModelText as _vModelText, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { class: "react-block" }
const _hoisted_2 = { class: "grid-contain" }
const _hoisted_3 = { class: "grid-content prefer-center react-block__content col6-width" }
const _hoisted_4 = {
  key: 0,
  class: "react-block__title"
}
const _hoisted_5 = {
  key: 1,
  class: "react-block__buttons"
}
const _hoisted_6 = { "aria-live": "polite" }
const _hoisted_7 = { key: 0 }
const _hoisted_8 = { class: "react-block__content-text content-text" }
const _hoisted_9 = { key: 2 }
const _hoisted_10 = { class: "react-block__input" }
const _hoisted_11 = {
  class: "react-block__email-trap",
  "aria-hidden": "true"
}
const _hoisted_12 = { class: "react-block__buttons" }
const _hoisted_13 = { "aria-live": "polite" }
const _hoisted_14 = {
  key: 0,
  class: "react-block__done"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_varma_button = _resolveComponent("varma-button")!
  const _component_varma_input = _resolveComponent("varma-input")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        (_ctx.step == 'Start' || _ctx.step == 'Form')
          ? (_openBlock(), _createElementBlock("h2", _hoisted_4, _toDisplayString(_ctx.title), 1))
          : _createCommentVNode("", true),
        (_ctx.step == 'Start')
          ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
              _createVNode(_component_varma_button, { onClick: _ctx.selectYes }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$t('/blocks/react/yes')), 1)
                ]),
                _: 1
              }, 8, ["onClick"]),
              _createVNode(_component_varma_button, { onClick: _ctx.selectNo }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$t('/blocks/react/no')), 1)
                ]),
                _: 1
              }, 8, ["onClick"])
            ]))
          : _createCommentVNode("", true),
        _createElementVNode("div", _hoisted_6, [
          (_ctx.step == 'Form')
            ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                _createElementVNode("div", _hoisted_8, [
                  _renderSlot(_ctx.$slots, "body")
                ])
              ]))
            : _createCommentVNode("", true)
        ]),
        (_ctx.step == 'Form')
          ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
              _createElementVNode("div", _hoisted_10, [
                _createElementVNode("div", _hoisted_11, [
                  _withDirectives(_createElementVNode("input", {
                    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.email) = $event)),
                    type: "text",
                    tabindex: "-1"
                  }, null, 512), [
                    [_vModelText, _ctx.email]
                  ])
                ]),
                _createVNode(_component_varma_input, {
                  ref: "body",
                  modelValue: _ctx.body,
                  "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.body) = $event)),
                  "label-text": _ctx.$t('/blocks/react/feedbackplaceholder'),
                  required: "",
                  type: "textarea",
                  "min-height": "64px",
                  rules: [_ctx.validateField]
                }, null, 8, ["modelValue", "label-text", "rules"])
              ]),
              _createElementVNode("div", _hoisted_12, [
                _createVNode(_component_varma_button, {
                  disabled: _ctx.submiting,
                  onClick: _ctx.submitForm
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.$t('/blocks/react/submit')), 1)
                  ]),
                  _: 1
                }, 8, ["disabled", "onClick"])
              ])
            ]))
          : _createCommentVNode("", true),
        _createElementVNode("div", _hoisted_13, [
          (_ctx.step == 'Done')
            ? (_openBlock(), _createElementBlock("div", _hoisted_14, _toDisplayString(_ctx.$t('/blocks/react/thankyou')), 1))
            : _createCommentVNode("", true)
        ])
      ])
    ])
  ]))
}