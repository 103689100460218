import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-7abd8137"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["href", "aria-current", "lang"]
const _hoisted_2 = { class: "text" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_varma_icon = _resolveComponent("varma-icon")!

  return (_openBlock(), _createElementBlock("a", {
    ref: "element",
    href: _ctx.href,
    class: _normalizeClass([{ active: _ctx.active }, "item"]),
    "aria-current": _ctx.active ? 'true': null,
    lang: _ctx.langCode,
    onFocusin: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.focusin && _ctx.focusin(...args))),
    onBlur: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.blur && _ctx.blur(...args)))
  }, [
    _createElementVNode("span", _hoisted_2, _toDisplayString(_ctx.text), 1),
    (_ctx.active)
      ? (_openBlock(), _createBlock(_component_varma_icon, {
          key: 0,
          name: "check-circle",
          size: "S",
          weight: "bold",
          "vertical-align": "middle"
        }))
      : _createCommentVNode("", true)
  ], 42, _hoisted_1))
}