
import { defineComponent, PropType, ref } from 'vue';
import { VarmaCardModel } from './VarmaCardModel';

import VarmaIcon from '../icons/VarmaIcon.vue';

export default defineComponent({
    components: {
        VarmaIcon
    },
    props: {
        article: { type: Object as PropType<VarmaCardModel>, required: true }
    },
    setup() {
        const articleLink = ref<HTMLLinkElement>();
        const focus = (): void => {
            if (articleLink.value) {
                articleLink.value.focus();
            }
        };

        return { articleLink, focus };
    },
    computed: {
        getImageStyle(): string {
            if (!this.article.imageUrl) {
                return '';
            }

            if (this.article.imageUrl[0] != '/') {
                return 'background-image: url(' + this.article.imageUrl + ')';
            }
            
            return 'background-image: url(' + this.article.imageUrl + '?preset=article-card)';
        },
        isEvent(): boolean {
            return this.article.type === 'Event' || this.article.type === 'Education' || this.article.type == 'WorkAbility';
        }
    }
});
